<template>
	<div>
		<div class="card">
			<div class="card-header justify-content-between">
				<div class="flex items-center flex-wrap justify-between gap-4">
					<div class="max-w-[400px]">
						<!-- <label for="">Select users</label> -->

						<multiselect v-model="users" id="select_users_input" :custom-label="({ fname, lname }) => `${fname} ${lname}`" track-by="id" placeholder="Type to search" open-direction="bottom" :options="usersList" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="true"
							:clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="10" :show-no-results="false" :hide-selected="false">
							<template slot="clear">
								<div class="multiselect__clear" v-if="users?.length" @mousedown.prevent.stop="users = []">
								</div>
							</template><span slot="noResult">Oops! No users found. Consider changing the search
								query.</span>
						</multiselect>
					</div>
					<div class="max-w-[400px]">
						<v-datepicker v-model="range" placholder="Filter by date" range></v-datepicker>
					</div>

					<select v-model="filter" class="p-1 rounded">
						<option value="all">All</option>
						<option value="credit">Credit</option>
						<option value="debit">Debit</option>
					</select>
					<button class="btn btn-primary whitespace-nowrap" :disabled="downloading" @click="download(walletId, filter, range[0], range[1], users.map((item) => item.id).join(','))">
						{{ downloading ? 'downloading' : 'Download' }}
					</button>
				</div>
			</div>
			<b-table striped hover selectable responsive :items="tableData" :fields="fields" :per-page="perPage" :busy="loading" empty-text="No transaction found" show-empty>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<strong>Loading...</strong>
					</div>
				</template>
				<template #empty="data">
					<p class="text-center">No transaction found</p>
				</template>
				<template #cell(id)="data">
					<div class="flex flex-col gap-1">
						<p class="text-xs text-[#6E717C]">{{ formatDates(data.item.created_at) }}</p>
						<div class="flex items-center gap-4 w-full max-w-[220px]">
							<div class="w-[40px] h-[40px] rounded-lg border flex items-center justify-center">
								<img v-if="data.item.status == 'pending'" src="@/assets/img/icons/wallet/pending.svg" alt="">
								<template v-else>
									<img v-if="data.item.direction == 'debit'" class="rotate-180" src="@/assets/img/icons/wallet/debit.svg" alt="">
									<img v-else class="rotate-180" src="@/assets/img/icons/wallet/credit.svg" alt="">
								</template>
							</div>
							<div class="flex flex-col w-[calc(220px-40px)] gap-1">
								<p class="text-[#575A65] text-xs font-medium overflow-clip max-w-[170px]">{{ data.item.description }}</p>
								<p class="text-[#6E717C] text-xs">
									{{ moment(data.item.created_at).format('h:mm A') }}
								</p>
							</div>
						</div>
					</div>
				</template>
				<template #cell(status)="data">
					<p :class="data.item.status == 'posted' ? 'text-[#109352]' : data.item.status == 'archived' ? 'text-[#E13D45]' : 'text-[#CBAF02]'">
						{{ data.item.status == 'posted' ? 'Successful' : data.item.status == 'archived' ? 'Failed' : data.item.status }}
					</p>
				</template>
			</b-table>

			<div class="card-footer" v-if="walletTransactions.length">
				<b-pagination v-model="currentPage" :total-rows="walletMetaData.total" :per-page="perPage"></b-pagination>
			</div>
		</div>
	</div>
</template>

<script setup>
import Swal from 'sweetalert2'
import Multiselect from 'vue-multiselect'
import { watch, ref, defineProps, computed, onMounted } from 'vue'
import { useWalletTransaction, useDownloadWallet } from '@/composables/backend/wallet'
import { useRoute } from 'vue-router/composables'
import moment from 'moment'
import { axiosInstance } from '@/plugins/axios'

const props = defineProps(['walletId'])
const route = useRoute()
const { loading, walletMetaData, walletTransactions, fetchTransactions } = useWalletTransaction()
const { loading: downloading, download } = useDownloadWallet()
const currentPage = ref(1)
const perPage = ref(10)
const fields = ref([
	{
		key: 'id',
		label: 'Transaction'
	},
	{
		key: 'direction',
		label: 'Type'
	},
	{
		key: 'amount_formatted',
		label: 'Amount'
	},
	{
		key: 'available_balance_before_formatted',
		label: 'Balance before'
	},
	{
		key: 'status',
		label: 'Status'
	}
])
const range = ref([])
const isLoading = ref(false)
const users = ref([])
const usersList = ref([])
const filter = ref('all')

const fetchUsers = () =>
{
	axiosInstance
		.get(`/v1/corporates/${route.params.companyId}/staff`)
		.then((response) =>
		{
			const total = response.data?.metadata?.total
			axiosInstance.get(`/v1/corporates/${route.params.companyId}/staff?metadata=true&limit=${total}&page=1`)
			.then((res) => {
				usersList.value = res.data?.data?.length ? res.data.data : []
			})
		})
		.catch((e) =>
		{
			usersList.value = []
		})
}
fetchUsers()

const formatDates = (str) =>
{
	const date = moment(str)
	const today = moment().startOf('day')
	const yesterday = moment().subtract(1, 'days').startOf('day')

	let formattedDate

	if (date.isSame(today, 'd')) {
		formattedDate = 'Today'
	} else if (date.isSame(yesterday, 'd')) {
		formattedDate = 'Yesterday'
	} else {
		formattedDate = date.format('Do MMMM, YYYY')
	}
	return formattedDate
}

const checkForExistingQuery = () =>
{
	fetchTransactions(props.walletId)
}

const tableData = computed(() =>
{
	return walletTransactions.value
})

watch(() => props.walletId, () =>
{
	const stringValue = users.value.map((item) => item.id).join(',')
	fetchTransactions(props.walletId, filter.value, range.value[0], range.value[1], stringValue)
})

watch(filter, () =>
{
	const stringValue = users.value.map((item) => item.id).join(',')
	fetchTransactions(props.walletId, filter.value, range.value[0], range.value[1], stringValue)
})
watch(users, (val) =>
{
	const stringValue = val.map((item) => item.id).join(',')
	fetchTransactions(props.walletId, filter.value, range.value[0], range.value[1], stringValue)
})

watch(range, (val) =>
{
	const stringValue = users.value.map((item) => item.id).join(',')
	fetchTransactions(props.walletId, filter.value, range.value[0], range.value[1], stringValue)
})

watch(currentPage, () =>
{
	const stringValue = users.value.map((item) => item.id).join(',')
	fetchTransactions(props.walletId, filter.value, range.value[0], range.value[1], stringValue, currentPage.value)
})

onMounted(() =>
{
	const query = route.query
	if (query.status == 'completed' || query.status == 'successful') {
		Swal.fire({ icon: 'success', title: 'Successful', text: 'Your wallet has been funded successfully' })
		fetchTransactions(props.walletId)
	}
})

checkForExistingQuery()
</script>
